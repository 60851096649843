<script>
import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";

/**
 * page-recruiter-directory component
 */

export default {
  data() {
    return {
      auth: localStorage.getItem("id_token") !== null ? true : false,
      token: `Bearer ${localStorage.getItem("token")}`,
      search_text: "",
      loading: true,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      recruiters: [],
      data: [],
      data2: [],
      data3: [],
      spinner: false,
      sectorsData: [],
      jobIndustries: [],
      selected_job_industry: [],
      // selected_job_secter: [],
      direct_employers: "",
      recruitment_agencies: "",
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
  },
  mounted() {
    this.load();
    this.getSectors();
    this.getJobIndustries();
  },
  methods: {
    load() {
      axios.get(`${API_URL}/get-company-data`).then((response) => {
        response.data.map((company) => {
          let temp = {};
          temp.companyName = company.company_name;
          temp.profilePic = company.profile_pic;
          temp.featured = company.feature;
          temp.jobPosted = company.job_count;
          this.data.push(temp);
          // console.log("oldddddddd", this.data);
        });
      });
    },
    search() {
      this.spinner = !this.spinner;
      if (this.search_text.length === 0) {
        this.data = this.data2;
        this.spinner = !this.spinner;
      } else {
        this.data2 = this.data;
        this.data = this.data.filter((cp) =>
          cp.companyName.includes(this.search_text)
        );
        this.spinner = !this.spinner;
      }
    },
    getSectors() {
      axios.get(`${this.apiUrl}/get-job-sectors`).then((response) => {
        this.sectorsData = response.data;
        this.loading = false;
      });
    },
    getJobIndustries() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-industries`).then((response) => {
        this.jobIndustries = response.data;
      });
    },
    filter() {
      // console.log(this.recruitment_agencies);
      // console.log(this.direct_employers);
      // console.log(this.selected_job_industry);
      // console.log(this.selected_job_secter);
      axios
        .post(`${API_URL}/company-data-filter`, {
          recruitment_agencies: this.recruitment_agencies,
          direct_employers: this.direct_employers,
          selected_job_industry: this.selected_job_industry,
          // selected_job_secter: this.selected_job_secter,
        })
        .then((response) => {
          console.log("response", response);
          response.data.map((company) => {
            let temp = {};
            temp.companyName = company.company_name;
            temp.profilePic = company.profile_pic;
            temp.featured = company.feature;
            temp.jobPosted = company.job_count;
            this.data = this.data3;
            this.data.push(temp);
            console.log("newwwwwww", this.data);
          });
        });
    },
    _jobIndustrySelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_industry.includes(event.target.value)) {
          var temp = this.selected_job_industry;
          temp.push(event.target.value);
          this.selected_job_industry = temp;
        }
      } else {
        var temp = [];
        this.selected_job_industry.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_industry = temp;
      }
    },
    // _jobSecterSelection(event) {
    //   if (event.target.checked) {
    //     if (!this.selected_job_secter.includes(event.target.value)) {
    //       var temp = this.selected_job_secter;
    //       temp.push(event.target.value);
    //       this.selected_job_secter = temp;
    //     }
    //   } else {
    //     var temp = [];
    //     this.selected_job_secter.forEach((element) => {
    //       if (element !== event.target.value) {
    //         temp.push(event.target.value);
    //       }
    //     });
    //     this.selected_job_secter = temp;
    //   }
    // },
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start -->
    <section class="section_ch_p bg-light d-table w-100 mt-10">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-4 l_r_p_z hide_on_mobile_ch mt-3">
            <form action="">
              <div class="card">
                <div class="card-headerch filter_headingch py-3">
                  Recruiter Type
                </div>
                <div class="card-body">
                  <ul style="margin-left: 3%">
                    <!-- <li>
                    <input
                      type="checkbox"
                      id="all"
                      name="all"
                      class="custom-control-input"
                      value="all"
                      v-on:change="filter()"
                    />
                    <label
                      for="all"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >All</label
                    >
                  </li> -->
                    <li>
                      <input
                        type="checkbox"
                        id="direct_employers"
                        name="direct_employers"
                        class="custom-control-input"
                        value="Direct Employer"
                        v-model="direct_employers"
                        v-on:change="filter()"
                      />
                      <label
                        for="direct_employers"
                        class="custom-control-label"
                        style="font-size: 14px"
                        >Direct Employers</label
                      >
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        id="recruitment_agencies"
                        name="recruitment_agencies"
                        class="custom-control-input"
                        value="Agency"
                        v-model="recruitment_agencies"
                        v-on:change="filter()"
                      />
                      <label
                        for="recruitment_agencies"
                        class="custom-control-label"
                        style="font-size: 14px"
                        >Recruitment Agencies</label
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card d-none">
                <div class="card-headerch filter_headingch py-3">
                  FILTER BY SECTORS
                </div>
                <div class="card-body new_multislect">
                  <ul class="ul_p_l display-in-grid-imb">
                    <!-- <li>
                    <input
                      type="checkbox"
                      id="sec_all"
                      name="sec_all"
                      class="custom-control-input"
                      value="all"
                    />
                    <label
                      for="sec_all"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >All</label
                    >
                  </li> -->
                    <!-- {{
                    this.sectorsData
                  }} -->
                    <li v-for="(item, index) in sectorsData" :key="index + 'g'">
                      <input
                        type="checkbox"
                        :id="item.job_function_name"
                        :name="item.job_function_name"
                        class="custom-control-input"
                        :value="item.job_function_name"
                        v-on:click="_jobSecterSelection($event)"
                        v-on:change="filter()"
                      /><label
                        class="custom-control-label"
                        :for="item.job_function_name"
                        style="font-size: 13px"
                        >{{ item.job_function_name }}</label
                      >
                    </li>
                  </ul>
                </div>
                <!-- <div class="card-body">
                <ul style="margin-left: 3%">
                  <li>
                    <input
                      type="checkbox"
                      id="sec_all"
                      name="sec_all"
                      class="custom-control-input"
                      value="all"
                    />
                    <label
                      for="sec_all"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >All</label
                    >
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="accountsfinance"
                      name="accountsfinance"
                      class="custom-control-input"
                      value="accountsfinance"
                    />
                    <label
                      for="accountsfinance"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >Accounts & Finance</label
                    >
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="bankings"
                      name="bankings"
                      class="custom-control-input"
                      value="bankings"
                    />
                    <label
                      for="bankings"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >Bankings</label
                    >
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="information_technology"
                      name="information_technology"
                      class="custom-control-input"
                      value="information_technology"
                    />
                    <label
                      for="information_technology"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >Information Technology</label
                    >
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="human_resource"
                      name="human_resource"
                      class="custom-control-input"
                      value="human_resource"
                    />
                    <label
                      for="human_resource"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >Human Resource</label
                    >
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="medical"
                      name="medical"
                      class="custom-control-input"
                      value="medical"
                    />
                    <label
                      for="medical"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >Medical</label
                    >
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="pharmacy"
                      name="pharmacy"
                      class="custom-control-input"
                      value="pharmacy"
                    />
                    <label
                      for="pharmacy"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >Pharmacy</label
                    >
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="telecommunication"
                      name="telecommunication"
                      class="custom-control-input"
                      value="telecommunication"
                    />
                    <label
                      for="telecommunication"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >Telecommunication</label
                    >
                  </li>
                </ul>
              </div> -->
              </div>
              <div class="card">
                <div class="card-headerch filter_headingch py-3">
                  FILTER BY INDUSTRY
                </div>
                <div class="card-body new_multislect">
                  <ul class="ul_p_l display-in-grid-imb">
                    <!-- <li>
                    <input
                      type="checkbox"
                      id="ind_all"
                      name="ind_all"
                      class="custom-control-input"
                      value="all"
                    />
                    <label
                      for="ind_all"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >All</label
                    >
                  </li> -->
                    <li
                      v-for="(item, index) in jobIndustries"
                      :key="index + 'g'"
                    >
                      <input
                        type="checkbox"
                        :id="item.industry_name"
                        :name="item.industry_name"
                        class="custom-control-input"
                        :value="item.industry_name"
                        v-on:click="_jobIndustrySelection($event)"
                        @click="filter()"
                      /><label
                        class="custom-control-label"
                        :for="item.industry_name"
                        style="font-size: 13px"
                        >{{ item.industry_name }}</label
                      >
                    </li>
                  </ul>
                </div>
                <!-- <div class="card-body ">
                <ul style="margin-left: 3%">
                  <li>
                    <input
                      type="checkbox"
                      id="ind_all"
                      name="ind_all"
                      class="custom-control-input"
                      value="all"
                    />
                    <label
                      for="ind_all"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >All</label
                    >
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="accounts"
                      name="accounts"
                      class="custom-control-input"
                      value="accounts"
                    />
                    <label
                      for="accounts"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >Accounts</label
                    >
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="bankings"
                      name="bankings"
                      class="custom-control-input"
                      value="bankings"
                    />
                    <label
                      for="bankings"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >Bankings</label
                    >
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="computer"
                      name="computer"
                      class="custom-control-input"
                      value="computer"
                    />
                    <label
                      for="computer"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >Computer</label
                    >
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      id="health"
                      name="health"
                      class="custom-control-input"
                      value="health"
                    />
                    <label
                      for="health"
                      class="custom-control-label"
                      style="font-size: 14px"
                      >Health</label
                    >
                  </li>
                </ul>
              </div> -->
              </div>
              <div class="align-center">
                <a href="/recruiter-directory" class="btn submitBnt mt-2">
                  <!-- <i class="fa fa-search text-white" style="font-size: 15px"></i> -->

                  Clear Search
                </a>
              </div>
            </form>
          </div>
          <div class="row hide_on_dectop_ch__ p-0">
            <!-- hide_by_ch -->
            <div class="col-lg-12 col-12 p-0">
              <div class="job_lit_heading">Recruiters directory</div>
            </div>
            <div class="col-lg-12 p-0">
              <div class="">
                <input
                  type="text"
                  class="form-control serach_i_ch"
                  placeholder="Search"
                  v-model="search_text"
                  @keyup.enter="search()"
                />
              </div>
              <button
                @click="search()"
                :class="{ spinner: spinner === true }"
                class="job_btn_serh_list"
              >
                Search
              </button>
            </div>
          </div>
          <div class="col-lg-9 col-md-8 pagech1 pagech1__">
            <div class="col-lg-12 job_listing_header p-0 hide_on_mobile_ch">
              <div class="row mx-0">
                <div class="col-6 p-0">
                  <div class="simple-heading">Recruiters directory</div>
                </div>
                <div class="col-6 p-0">
                  <span class="float-lg-right"
                    ><div role="search" class="navbar-form">
                      <div class="input-group stylish-input-group">
                        <div class="">
                          <input
                            type="text"
                            class="form-control serach_i_ch"
                            placeholder="Search"
                            v-model="search_text"
                            @keyup.enter="search()"
                          />
                        </div>
                        <span class="input-group-addon"
                          ><button
                            @click="search()"
                            type="button"
                            class="ch_btn_submit mt-1"
                          >
                            <span class="fa fa-search"></span></button
                        ></span>
                      </div></div
                  ></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-6 col-sm-6 col-md-4 col-lg-3 l_p_z"
                v-for="(item, index) in data"
                v-bind:key="index"
              >
                <div class="thumbnailch chhight_col">
                  <img
                    class="companyImage"
                    :src="
                      item.profilePic != ''
                        ? mediaUrl + item.profilePic
                        : 'https://via.placeholder.com/200X200.png'
                    "
                    alt="Logo"
                    style="width: 100%"
                  />
                  <div id="labelonech">
                    <p class="labelch rounded-0 label-primary pull-right m_r_1">
                      Verified
                    </p>
                  </div>
                  <div id="labeltwoch">
                    <p
                      v-if="item.featured === '1'"
                      class="
                        labelch
                        rounded-0
                        label-info
                        pull-right
                        feature_label_imb
                      "
                    >
                      Featured
                    </p>
                  </div>

                  <div class="captionch align-center">
                    <h4>
                      <router-link
                        :to="
                          '/employer/' +
                          item.companyName
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '')
                        "
                        class=""
                      >
                        {{ item.companyName }}
                      </router-link>
                    </h4>
                    <p class="text-muted">{{ item.jobPosted }} Jobs</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <!-- <arrow-up-icon class="icons"></arrow-up-icon> -->
    </a>
    <!-- Back to top -->
  </div>
</template>
